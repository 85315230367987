import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { CurveCarveTop, CurveCarveBottom } from "../components/Curves/Curve"
import { FaExclamationTriangle } from "react-icons/fa"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Page Not Found" />

    <Container className="text-center">
      <Row>
        <Col>
          <h1>404 - Page Not Found</h1>
        </Col>
      </Row>
    </Container>

    <CurveCarveTop variant="light" />
    <div className="bg-light  pt-5">
      <Container className="text-center">
        <Row>
          <Col>
            <p>Sorry, we couldn't find the page you were looking for...</p>
            <div className="my-5">
              <FaExclamationTriangle size="100px" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <CurveCarveBottom variant="light" />

    <div className="text-center ">
      <Button as={Link} size="lg" variant="secondary" to="/">
        Return Home
      </Button>
    </div>
  </Layout>
)

export default NotFoundPage
